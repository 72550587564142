export const strict = false;

export const state = () => ({
  account: {
    email: ''
  },
  fullPincode: null
})

export const actions = {
  patchUser({commit}, data) {
    var loadingObj = {
      content: 'user',
      type: 'patch'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/user/edit', data)
    .then(response => { 
      this.$auth.fetchUser();
      loadingObj.type = 'done';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    }).catch((error) => {
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      this.$sentry.captureException(error);
    });
  },   
  checkEmail({commit}, payload) {
    const data = {
      email: payload.email
    }
    this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/user-check-email', data)
    .then(response => { 
      console.log(response.data.used);
      if (response.data && response.data.used && payload.location && payload.location != 'paywall') {
        payload.router.push('/account/inloggen');
      }
      if (response.data && response.data.used != 1 && payload.location && payload.location != 'paywall') {
       payload.router.push('/account/registreren');
     }
     if (response.data && response.data.used && payload.location && payload.location === 'paywall') {
      commit('paywallStore/setAccountState', 'login', { root: true });
    }
    if (response.data && response.data.used != 1 && payload.location && payload.location === 'paywall') {
      commit('paywallStore/setAccountState', 'register', { root: true });
    }
    commit('modalStore/setModal', false, { root: true });
  }).catch((error) => {

    this.$sentry.captureException(error);
  });
},   

toggleMail({commit}, data) {
  var loadingObj = {
    content: 'user',
    type: 'segment'
  };
  var todo = 'remove';
  commit('loadstateStore/setLoadState', loadingObj, { root: true });
  if (data.subscribe === 1) {
    todo = 'add'
  }
  this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/segment/' + todo + '-user/' + data.segment)
  .then(response => { 
    this.$auth.fetchUser();
    loadingObj.type = 'done';
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    this.$sentry.captureException(error);

  }).catch((error) => {
    console.log(error);
    loadingObj.type = 'error';
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    this.$sentry.captureException(error);
  });
},   
toggleFavorite({commit}, data) {
  if (data.subscribe === 1) {
    this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/favorite', data)
    .then(response => { 
      this.$auth.fetchUser();
    }).catch((error) => {
      console.log(error);
      this.$sentry.captureException(error);
    });
  } else {
    this.$axios.delete(process.env.API_URL + process.env.API_PREFIX + '/favorite/' + data.resource_id, data)
    .then(response => { 
      this.$auth.fetchUser();
    }).catch((error) => {
      console.log(error);
      this.$sentry.captureException(error);
    });
  }
},
toggleFavoMail({commit}, data) {
  var loadingObj = {
    content: 'user',
    type: 'favorite'
  };
  commit('loadstateStore/setLoadState', loadingObj, { root: true });
  this.$axios.post(process.env.API_URL + process.env.API_PREFIX + '/favorite-' + data.url + '/' + data.resource_id + '/mailing', data.todo)
  .then(response => { 
    this.$auth.fetchUser();
    loadingObj.type = 'done';
    commit('loadstateStore/setLoadState', loadingObj, { root: true });

  }).catch((error) => {
    console.log(error);
    loadingObj.type = 'error';
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    this.$sentry.captureException(error);
  });
},
removeUser({commit}, user_id) {
  var loadingObj = {
    content: 'user',
    type: 'remove'
  };
  commit('loadstateStore/setLoadState', loadingObj, { root: true });
  var self = this;
  this.$axios.delete(process.env.API_URL + process.env.API_PREFIX + '/user/delete')
  .then(response => { 
    self.$auth.logout();
    self.$router.push({
      path: '/?message=Je%20account%20is%20succesvol%20verwijderd'
    })
    loadingObj.type = 'done';
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
  }).catch((error) => {
    loadingObj.type = 'error';
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    var alertObj = {};
    alertObj.type = 'error';
    alertObj.href = '/info/contact';
    alertObj.message = 'Er is iets misgegaan. Klik op deze melding om contact op te nemen.';
    commit('alertStore/setAlert', alertObj, { root: true });    
  });
},
}

export const getters = {

}

export const mutations = {
  setLoadState(state, loadingObj) {
    state.loadState[loadingObj.content] = loadingObj.type;
  },
  setPagination(state, paginationObj) {
    state.pagination[paginationObj.content] = paginationObj.pagination;
  },
  setEmail(state, email) {
    state.account.email = email;
  },
  setFullPincode(state, pincode) {
    state.fullPincode = pincode;
  }
}