export default function ({ route, redirect, store, app }) {
  const message = route.query.message;
  if (message) {
    var alertObj = {
      type: 'info',
      message: message
    }
    store.commit('alertStore/setAlert', alertObj);
  }
}
